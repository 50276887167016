import { GlobalContext } from "@/lib/contexts/GlobalContext";
import React, { useContext, useEffect } from "react";
import { CheckOutContext } from "../Contexts/ICheckOutContext";
import InvoiceTemplate from "../InvoiceTemplates/InvoiceTemplate";

export interface IInvoiceSelectorProps {
    setValidate?: (valid: boolean) => void;
}
export interface IInvoiceSelectorRef {
    validate: () => boolean;
    data: () => Record<string, string> | undefined;
}

const InvoiceSelector = React.forwardRef((props: IInvoiceSelectorProps, ref: React.ForwardedRef<IInvoiceSelectorRef | undefined>) => {
    const { merchantPortalOptionSetting, refetchMerchantPortalOptionSetting } = useContext(GlobalContext);
    const { setInvoiceCountry, setInvoiceValidate } = useContext(CheckOutContext);
    useEffect(() => {
        refetchMerchantPortalOptionSetting();
    }, []);

    useEffect(() => {
        if (!merchantPortalOptionSetting?.isEnableInovice) {
            //if invoice is not enable, set validate to true
            setInvoiceCountry('Unset');
            setInvoiceValidate(true);
        }
    }, [merchantPortalOptionSetting]);

    if (merchantPortalOptionSetting?.isEnableInovice) {
        return (
            <InvoiceTemplate></InvoiceTemplate>
        );
    }
    return (
        <div>
        </div>
    );
});

export default InvoiceSelector;