import { useGetItemsByMerchantApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { PagesTemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useContext, useMemo } from "react";
import { useQuery } from "react-query";


const HomePage: React.FC = React.memo(() => {
  const { merchantId, deviceType, merchantPortalOptionSetting, merchantPortalStyleSettings, messageApi, merchantMainMarqueeViewModel } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const { data: merchantPaginationItems, error, isLoading: isLoadingItems, refetch } = useQuery("GetItemsByMerchant", async () => await useGetItemsByMerchantApi({
    merchantId: merchantId,
    page: 1,
    pageSize: 8
  }));

  const portalStyle = merchantPortalStyleSettings.find(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'HomePage');

  const TemplateComponent = useMemo(() => {
    return dynamic<PagesTemplateProps>(() =>
      import(`../../Templates/Shop/Pages/HomePage/Template_${portalStyle?.style || '0'}.tsx`),
      {
        loading: () => <LoadingComponent />,
        ssr: false
      }
    );
  }, [portalStyle?.style]);

  return (
    <TemplateComponent
      merchantId={merchantId}
      deviceType={deviceType}
      portalOptionSettingViewModel={merchantPortalOptionSetting}
      portalStyleSettings={merchantPortalStyleSettings}
      translate={translate}
      messageApi={messageApi}
      isPreviewMode={false}
      config={portalStyle?.styleSetting || {}}
      items={merchantPaginationItems?.result}
      isLoading={isLoadingItems}
      mainMarqueeViewModel={merchantMainMarqueeViewModel}
    />
  );
});

export default HomePage;