export class UrlHelper {
    pageFormPost(url: string, params: { [key: string]: string }, target: string = '_self') {
        params = { ...params, 'ApiUrl': url }
        const queryString = this.createQueryString(params);
        url = `pageFormPost?${queryString}`;
        window.open(url, target);
    }

    openInPopup(url: string, params: { [key: string]: string }) {
        const queryString = this.createQueryString(params);
        url = `${url}?${queryString}`;
        const windowName = 'newPopup';
        const windowSize = 'width=800,height=600';
        window.open(url, windowName, windowSize);
    }

    openRedirect(url: string, params: { [key: string]: string }) {
        const queryString = this.createQueryString(params);
        url = `${url}?${queryString}`;
        window.open(url, '_self');
    }

    createQueryString(params: { [key: string]: string }) {
        return Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');
    }
}