import { Checkbox, Form, Image, Input, Radio, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import taiwanCitizenDigitalCertificate from '../../../assets/invoice/TaiwanCitizenDigitalCertificate.jpeg';
import taiwanElectronicVehicle from '../../../assets/invoice/TaiwanElectronicVehicle.png';
import { CheckOutContext } from "../Contexts/ICheckOutContext";

const TaiwanInvoiceFields = () => {
    const { invoiceValues, setInvoiceValues, invoiceValidate, setInvoiceValidate } = useContext(CheckOutContext);

    const [localValues, setLocalValues] = useState({});

    const formatLocalValues = (values) => {
        const formattedValues = Object.keys(values).reduce((acc, key) => {
            // 将键首字母大写
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            // 确保值是字符串，对于 boolean 和其他类型进行转换
            const valueAsString = String(values[key]);
            // 组成新的记录
            acc[capitalizedKey] = valueAsString;
            return acc;
        }, {});

        return formattedValues;
    };

    useEffect(() => {
        setInvoiceValues({
            InvoiceType: 'Donate',
            DonateTo: '1',
            ElectronicType: '',
            PersonalDeviceInput: '',
            CitizenCertificateInput: '',
            CompanyDetails: 'false',
            CompanyUnifiedNumber: '',
            CompanyTitle: ''
        });
    }, []);


    useEffect(() => {
        setLocalValues(invoiceValues);
    }, [invoiceValues]);

    useEffect(() => {
        validateForm(localValues).then(isValid => setInvoiceValidate(isValid));
    }, [localValues]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setInvoiceValues(prevValues => ({
            ...prevValues,
            [name]: inputValue,
        }));
    };

    const validateForm = async (values) => {
        try {
            if (values.InvoiceType === 'Electronic') {
                if (!values.ElectronicType) {
                    return false;
                }
                if (values.ElectronicType === 'PersonalDevice' && !/^\/[A-Za-z0-9]{7}$/.test(values.PersonalDeviceInput.trim())) {
                    return false;
                }
                if (values.ElectronicType === 'CitizenCertificate' && !/^[A-Z]{2}[0-9]{14}$/.test(values.CitizenCertificateInput.trim())) {
                    return false;
                }
            } else if (values.InvoiceType === 'Donate' && !values.DonateTo.trim()) {
                return false;
            } else if (values.InvoiceType === 'Paper') {
                if (values.CompanyDetails && values.CompanyDetails !== 'false') {
                    if (!values.CompanyUnifiedNumber.trim() || !values.CompanyTitle.trim()) {
                        return false;
                    }
                }
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    return (
        <div style={{ margin: '10px', display: 'flex', justifyContent: 'center' }}>
            <Form>
                <Form.Item>
                    <label>請選擇發票類型:</label>
                    <Radio.Group name="InvoiceType" value={localValues['InvoiceType']} onChange={handleInputChange}>
                        <Radio value="Electronic">電子發票載具</Radio>
                        <Radio value="Donate">捐贈發票</Radio>
                        <Radio value="Paper">是否要紙本發票</Radio>
                    </Radio.Group>
                </Form.Item>

                {localValues['InvoiceType'] === 'Electronic' && (
                    <>
                        <Form.Item>
                            <label>請選擇電子發票載具類型:</label>
                            <Radio.Group name="ElectronicType" value={localValues['ElectronicType']} onChange={handleInputChange}>
                                <Radio value="PersonalDevice">個人載具</Radio>
                                <Radio value="CitizenCertificate">自然人憑證</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {localValues['ElectronicType'] === 'PersonalDevice' && (
                            <>
                                <Image src={taiwanElectronicVehicle} width={'300px'} alt="個人載具" />
                                <Form.Item>
                                    <label>個人載具信息:</label>
                                    <Input
                                        name="PersonalDeviceInput"
                                        value={localValues['PersonalDeviceInput'] || ""}
                                        onChange={handleInputChange}
                                        placeholder="/OOOOOOO"
                                    />
                                </Form.Item>
                            </>
                        )}

                        {localValues['ElectronicType'] === 'CitizenCertificate' && (
                            <>
                                <Image width={'300px'} src={taiwanCitizenDigitalCertificate} alt="自然人憑證" />
                                <Form.Item>
                                    <label>自然人憑證信息:</label>
                                    <Input
                                        name="CitizenCertificateInput"
                                        value={localValues['CitizenCertificateInput'] || ""}
                                        onChange={handleInputChange}
                                        placeholder="TP03000001234567"
                                    />
                                </Form.Item>
                            </>
                        )}
                    </>
                )}

                {localValues['InvoiceType'] === 'Donate' && (
                    <Form.Item>
                        <label>捐贈單位名稱:</label>
                        <Select
                            value={localValues['DonateTo']}
                            onChange={value => handleInputChange({ target: { name: 'DonateTo', value: value } })}
                        >
                            {/* 可新增 */}
                            <Select.Option value="168001">OMG關懷社會愛心基金會</Select.Option>
                        </Select>
                    </Form.Item>
                )}

                {localValues['InvoiceType'] === 'Paper' && (
                    <>
                        <Form.Item>
                            <Checkbox
                                name="CompanyDetails"
                                checked={localValues['CompanyDetails'] && localValues['CompanyDetails'] !== 'false'} // Convert to boolean
                                onChange={handleInputChange}
                            >
                                是否需要統編？
                            </Checkbox>
                        </Form.Item>
                        {localValues['CompanyDetails'] && localValues['CompanyDetails'] !== 'false' && (
                            <>
                                <Form.Item>
                                    <label>統一編號:</label>
                                    <Input
                                        name="CompanyUnifiedNumber"
                                        value={localValues['CompanyUnifiedNumber'] || ""}
                                        onChange={handleInputChange}
                                        placeholder="請輸入統一編號"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <label>公司擡頭:</label>
                                    <Input
                                        name="CompanyTitle"
                                        value={localValues['CompanyTitle'] || ""}
                                        onChange={handleInputChange}
                                        placeholder="請輸入公司擡頭"
                                    />
                                </Form.Item>
                            </>
                        )}
                        <p>紙本發票將會隨商品一同寄出</p>
                    </>
                )}
            </Form>
        </div>
    );
};

export default TaiwanInvoiceFields;
