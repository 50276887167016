import { CurrencyCode } from '@/enums/CurrecnyCode';
import { IAddItemToShoppingCartItem, IQueryItemByMerchantRequest } from '@/interfaces/Requests';
import { useAddToShoppingCartApi, useAddToTempShoppingCartApi, useGetItemSpecsApi, useGetItemsByMerchantApi } from '@/lib/api/apis';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { SignHelper } from '@/lib/helpers/SignHelper';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { MerchantCheckoutType, MerchantPortalStyleSettingType } from '@/Templates/enums/templateEnums';
import { IItemSpec, IItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { ItemDetailTemplateProps } from '@/Templates/TemplateProps';
import JSONBig from 'json-bigint';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

const ItemDetailPage: React.FC = React.memo(() => {
    const signHelper = new SignHelper();
    const {
        sendGAEvent,
        shoppingCartItemCount,
        messageApi,
        isLogin,
        merchantId,
        deviceType,
        merchantPortalOptionSetting,
        merchantPortalStyleSettings,
        tryGetSessionId,
        refreshShoppingCartItemCount,
        merchantMainMarqueeViewModel
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const itemId = BigInt(queryParams.get('itemId') || '0');

    const [queryRequest] = useState<IQueryItemByMerchantRequest>({
        merchantId: merchantId,
        page: 1,
        pageSize: 1,
        extraItemIds: [itemId]
    });
    const [item, setItem] = useState<IItemViewModel | undefined>(undefined);
    const [itemSpecs, setItemSpecs] = useState<IItemSpec[]>([]);
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpec | null>(null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(0);
    const [shoppingCartItem, setShoppingCartItem] = useState<IAddItemToShoppingCartItem>({
        itemId: item?.id || BigInt(0),
        itemSpecId: selectedItemSpec?.id || BigInt(0),
        buyAmount: purchaseQuantity,
        currency: selectedItemSpec?.currency || CurrencyCode.TWD,
    });
    const [hash, setHash] = useState<string>("");

    const { mutate: getItems, isLoading: isLoadingItems } = useMutation(useGetItemsByMerchantApi, {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                if (response.result.data.length > 0) {
                    setItem(response.result.data[0]);
                    getItemSpecs(merchantId);
                } else {
                    navigate(`/${merchantId}/notfound`);
                }
            }
        }
    });

    const { mutate: getItemSpecs } = useMutation(
        async (merchantId: BigInt) => await useGetItemSpecsApi(merchantId, itemId),
        {
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    const data = response.result;
                    setItemSpecs(data);
                    setSelectedItemSpec(data[0] ? data[0] : null);
                } else {
                    messageApi.error(response.message || 'Operation failed');
                }
            }
        }
    );

    useEffect(() => {
        const generateSign = async () => {
            const st = merchantPortalOptionSetting?.merchantCheckoutType === MerchantCheckoutType.Normal
                ? `${merchantId}:${JSONBig.stringify(shoppingCartItem)}`
                : `${merchantId}:${tryGetSessionId}:${JSONBig.stringify(shoppingCartItem)}`;
            const sign = await signHelper.generateDynamicTimeSignAsync(st, new Date());
            setHash(sign);
        };
        generateSign();
    }, [tryGetSessionId, shoppingCartItem, merchantId, merchantPortalOptionSetting?.merchantCheckoutType]);

    const { refetch: addToTempShoppingCartApiRefetch, isLoading: isAddToTempShoppingCartLoading } = useQuery("addToTempShoppingCart", async () => await useAddToTempShoppingCartApi({
        merchantId: merchantId,
        sessionId: tryGetSessionId(),
        addItemToShoppingCartItem: shoppingCartItem,
        hash: hash
    }), {
        enabled: false, retry: false,
        onSuccess: (data) => {
            if (data?.isSuccess) {
                messageApi.success(translate('Operation success'));
                setPurchaseQuantity(0);
            } else {
                messageApi.error(data?.message);
            }
        }
    });

    const { refetch: addToShoppingCartApiRefetch, isLoading: isAddToShoppingCartLoading } = useQuery("useAddToShoppingCartApi", async () => await useAddToShoppingCartApi({
        addItemToShoppingCartItem: shoppingCartItem,
        hash: hash
    }), {
        enabled: false, retry: false,
        onSuccess: (data) => {
            if (data?.isSuccess) {
                messageApi.success(translate('Operation success'));
                setPurchaseQuantity(0);
            } else {
                messageApi.error(data?.message);
            }
        }
    });

    const handleSelectChange = useCallback((value: string) => {
        const itemSpecId = BigInt(value);
        const itemSpec = itemSpecs.find(item => item.id.toString() === itemSpecId.toString());
        setSelectedItemSpec(itemSpec || null);
        setPurchaseQuantity(0);
    }, [itemSpecs]);

    useEffect(() => {
        getItems(queryRequest);
    }, [queryRequest, getItems]);

    useEffect(() => {
        setShoppingCartItem({
            itemId: item?.id || BigInt(0),
            itemSpecId: selectedItemSpec?.id || BigInt(0),
            buyAmount: purchaseQuantity,
            currency: selectedItemSpec?.currency || CurrencyCode.TWD,
        });
    }, [purchaseQuantity, selectedItemSpec, item]);

    const handleSubmit = useCallback(async () => {
        sendGAEvent('add_to_cart', {
            currency: shoppingCartItem.currency,
            value: shoppingCartItem.buyAmount * (selectedItemSpec?.sellPrice || 0),
            items: [
                {
                    item_id: item?.id.toString(),
                    item_name: item?.name,
                    affiliation: merchantPortalOptionSetting?.merchantName,
                    coupon: "None",
                    discount: 0,
                    index: 0,
                    item_brand: merchantPortalOptionSetting?.merchantName,
                    item_category: selectedItemSpec?.name,
                    price: selectedItemSpec?.sellPrice,
                    quantity: shoppingCartItem.buyAmount,
                }
            ]
        });
        if (isLogin) {
            await addToShoppingCartApiRefetch();
        } else {
            await addToTempShoppingCartApiRefetch();
        }
        await refreshShoppingCartItemCount(isLogin);
    }, [sendGAEvent, shoppingCartItem, selectedItemSpec, item, isLogin, addToShoppingCartApiRefetch, addToTempShoppingCartApiRefetch, refreshShoppingCartItemCount, merchantPortalOptionSetting?.merchantName]);

    const portalStyle = useMemo(() => merchantPortalStyleSettings.find(
        x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'ItemDetailPage'
    ), [merchantPortalStyleSettings]);

    const TemplateComponent = useMemo(() => dynamic<ItemDetailTemplateProps>(() =>
        import(`../../Templates/Shop/Pages/ItemDetailPage/Template_${portalStyle?.style || '0'}.tsx`),
        {
            loading: () => <></>,
            ssr: false
        }
    ), [portalStyle?.style]);

    if (isLoadingItems || !item) {
        return <LoadingComponent />;
    }

    return (
        <TemplateComponent
            merchantId={merchantId}
            deviceType={deviceType}
            portalOptionSettingViewModel={merchantPortalOptionSetting}
            translate={translate}
            portalStyleSettings={merchantPortalStyleSettings}
            isPreviewMode={false}
            messageApi={messageApi}
            item={item}
            config={portalStyle?.styleSetting || {}}
            itemSpecs={itemSpecs}
            selectedItemSpec={selectedItemSpec}
            purchaseQuantity={purchaseQuantity}
            shoppingCartItemCount={shoppingCartItemCount}
            handleSelectChange={handleSelectChange}
            setPurchaseQuantity={setPurchaseQuantity}
            handleSubmit={handleSubmit}
            isAddToShoppingCartLoading={isAddToShoppingCartLoading}
            isAddToTempShoppingCartLoading={isAddToTempShoppingCartLoading}
            isLoading={isLoadingItems}
            mainMarqueeViewModel={merchantMainMarqueeViewModel}
        />
    );
});

export default ItemDetailPage;