



import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Flex, Typography } from 'antd';
import React, { useContext, useEffect } from "react";
import './DisclaimerPage.css'; // 引入自定义的 CSS 文件

const { Title, Paragraph } = Typography;
const DisclaimerPage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { merchantPortalOptionSetting } = useContext(GlobalContext);
    const [platformName, setPlatformName] = React.useState<string>('MicroShop');
    useEffect(() => {
        if (merchantPortalOptionSetting) {
            setPlatformName(merchantPortalOptionSetting.merchantName);
        }
    }, [merchantPortalOptionSetting]);
    return (
        <Flex justify="center">
            <Typography style={{ padding: '20px', maxWidth: '50%' }}>
                <Title level={1} className="title-center">免責聲明</Title>
                <Paragraph className="title-center">歡迎來到 {platformName} 。在您使用本平台服務之前，請仔細閱讀以下免責聲明。</Paragraph>
                <Title level={2} className="title-center">內容聲明</Title>
                <Paragraph className="title-center">本平台上的所有內容，包括但不限於文本、圖像、視頻、音頻和鏈接，僅供參考。我們不保證內容的準確性、完整性或可靠性。使用或依賴任何此類內容均由您自行承擔風險。</Paragraph>
                <Title level={2} className="title-center">第三方鏈接</Title>
                <Paragraph className="title-center">本平台可能含有指向第三方網站的鏈接。這些鏈接僅作為便利提供，不代表我們對這些網站的內容負責。訪問這些網站將不受我們的控制和免責。</Paragraph>
                <Title level={2} className="title-center">版權與知識產權</Title>
                <Paragraph className="title-center">本平台上的所有內容（除了特別標明屬於第三方的內容）均為 {platformName} 或其內容供應商的財產，受到版權和其他知識產權法律的保護。未經明確的書面許可，不得覆制、修改、分發或以任何方式商業利用這些內容。</Paragraph>
                <Title level={2} className="title-center">用戶責任</Title>
                <Paragraph className="title-center">用戶在本平台上的行為應遵守所有適用的本地、國家和國際法律和規定。用戶對其在平台上發布或傳輸的內容全權負責。</Paragraph>
                <Title level={2} className="title-center">免責</Title>
                <Paragraph className="title-center">在法律允許的最大範圍內，{platformName} 不對因使用本平台或其內容而導致的任何直接、間接、特殊、後果性或懲罰性損害承擔責任。</Paragraph>
                <Title level={2} className="title-center">修改</Title>
                <Paragraph className="title-center">我們保留隨時修改本免責聲明的權利，且不另行通知。請定期查看此聲明，因為您繼續使用本平台即表示接受這些更改。</Paragraph>
            </Typography>
        </Flex>
    );
};

export default DisclaimerPage;

