export enum MerchantPortalStyleSettingType {
    None = 'None',
    Component = 'Component',
    Pages = 'Pages'
}

export enum NavBarClickType {
    None = 'None',
    RedirectPage = 'RedirectPage',
    ItemSearch = 'ItemSearch',
}

export enum MerchantCheckoutType {
    // 需註冊
    Normal = "Normal",
    // 允許加入臨時購物車，結帳需要會員
    AllowTempShoppingCart = "AllowTempShoppingCart",
    // 允許不註冊結帳
    AllowNoneRegist = "AllowNoneRegist",
}

export enum MarqueeType {
    /// <summary>
    /// 商戶主頁
    /// </summary>
    MerchantMain = 'MerchantMain',
    /// <summary>
    /// 系統使用
    /// </summary>
    SystemUsage = 'SystemUsage',
}

export enum MerchantPortalStyleShopPages {
    LandingPage = 'LandingPage',
    HomePage = 'HomePage',
    SearchPage = 'SearchPage',
    ItemDetailPage = 'ItemDetailPage',
    ShoppingCartPage = 'ShoppingCartPage',
    OrderConfirmationPage = 'OrderConfirmationPage',
    OrderHistoryPage = 'OrderHistoryPage',
    AccountPage = 'AccountPage'
}

export enum MerchantPortalStyleShopComponents {
    ItemCard = 'ItemCard',
    ItemDetail = 'ItemDetail',
    ShoppingCart = 'ShoppingCart',
    OrderConfirmation = 'OrderConfirmation',
    OrderHistory = 'OrderHistory',
    Account = 'Account',
    NavBar = 'NavBar',
    MainMarquee = 'MainMarquee',
    ScrollToTopBtn = 'ScrollToTopBtn',
}

export enum DeviceType {
    Mobile = 'Mobile',
    LapTop = 'LapTop',
    Desktop = 'Desktop',
}