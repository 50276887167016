import { CheckCircleOutlined, ShoppingCartOutlined, ShoppingOutlined, TransactionOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, Flex, List, Spin, StepProps, Steps, Typography } from "antd";
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { BASE_PATH } from "@/constants/routes";
import { IRemoveItemsFromTempShoppingCartRequest, IRemoveItemsToShoppingCartRequest } from "@/interfaces/Requests";
import { IShoppingCartItemViewModel } from "@/interfaces/Responses";
import { IReceiverInfos } from "@/interfaces/VIewModels";
import { useGetMemberInfoApi } from "@/lib/api/Portal/members";
import {
  useGetShoppingCartApi,
  useGetTempShoppingCartApi,
  useRemoveItemsFromTempShoppingCartApi,
  useRemoveItemsToShoppingCartApi
} from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import imageNone from '../../assets/None.png';
import { LocalStorageHelper } from '../../lib/helpers/LocalStorageHelper';
import { CheckOutContext } from "./Contexts/ICheckOutContext";

import CheckoutOrder from "@/pages/ShoppingCartPages/Components/CheckoutOrder";
import LogisticSelector from "@/pages/ShoppingCartPages/Components/LogisticSelector";
import PaymentSelector from "@/pages/ShoppingCartPages/Components/PaymentSelector";

import { DeviceType } from '@/Templates/enums/templateEnums';
import { LogisticOrderLimitType } from '@/enums/ThirdPartyLogistic';
import './ShoppingCartPage.css';

const { Text } = Typography;

export enum Step {
  SelectItem,
  Logistic,
  Payment,
  Confirm
}

const ShoppingCartPage: React.FC = () => {
  const localStorageHelper = new LocalStorageHelper();
  const navigate = useNavigate();
  const { messageApi, sendGAEvent, merchantPortalOptionSetting, isLogin, deviceType, merchantId, tryGetSessionId, refreshShoppingCartItemCount } = useContext(GlobalContext);
  const { translate, i18nLanguage } = useContext(TranslationContext);
  const { data, refetch, isLoading, isFetched } = useShoppingCartData(merchantId, isLogin, tryGetSessionId);
  const [stepItems, setStepItems] = useState<StepProps[]>([]);
  const {
    selectedItems, setSelectedItems,
    selectedLogisticOption,
    selectedPayment, setSelectedPayment,
    userWantReservePay, setUserWantReservePay,
    logisticValues,
    paymentValues,
    receiverInfos, setReceiverInfos
  } = useContext(CheckOutContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [listWidth, setListWidth] = useState(80);

  const [normalItems, setNormalItems] = useState<IShoppingCartItemViewModel[]>([]);
  const [frozenItems, setFrozenItems] = useState<IShoppingCartItemViewModel[]>([]);
  const [isNormalSelected, setIsNormalSelected] = useState(false);
  const [isFrozenSelected, setIsFrozenSelected] = useState(false);

  const getLogisticsWarning = () => {
    return i18nLanguage === 'zh_TW'
      ? "不可與不同物流類型一同結帳"
      : "Cannot checkout with different logistic types together";
  };

  useEffect(() => {
    setListWidth(deviceType === DeviceType.Mobile ? 100 : 80);
  }, [deviceType]);

  useEffect(() => {
    refetch();
    if (isLogin) {
      loadMemberInfo(setReceiverInfos);
    }
  }, [isLogin]);

  useEffect(() => {
    if (isFetched) {
      getSessionSelectedShoppingCartOption(data, setSelectedItems, setReceiverInfos, setCurrentStep, localStorageHelper);
    }
  }, [isFetched]);

  useEffect(() => {
    if (currentStep === Step.SelectItem) {
      setUserWantReservePay(false);
      setSelectedItems([]);
    }
    if (currentStep === Step.Payment) {
      setSelectedPayment(undefined);
    }
  }, [currentStep]);

  useEffect(() => {
    setStepItems(generateStepItems(userWantReservePay, translate));
  }, [userWantReservePay, i18nLanguage]);

  useEffect(() => {
    if (data?.result?.shoppingCartItemViewModels) {
      setNormalItems(data.result.shoppingCartItemViewModels.filter(item => item.logisticOrderLimitType === LogisticOrderLimitType.None));
      setFrozenItems(data.result.shoppingCartItemViewModels.filter(item => item.logisticOrderLimitType === LogisticOrderLimitType.Frozen));
    }
  }, [data]);

  useEffect(() => {
    setIsNormalSelected(selectedItems.some(item => item.logisticOrderLimitType === LogisticOrderLimitType.None));
    setIsFrozenSelected(selectedItems.some(item => item.logisticOrderLimitType === LogisticOrderLimitType.Frozen));
  }, [selectedItems]);

  const handleSelectAll = (items: IShoppingCartItemViewModel[], isSelected: boolean) => {
    if (isSelected) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectItem = (item: IShoppingCartItemViewModel, checked: boolean) => {
    if (checked) {
      if (selectedItems.length > 0) {
        if (selectedItems[0].currency !== item.currency) {
          messageApi.error(translate('You can only select items with the same currency.'));
          return;
        }
        if (selectedItems[0].logisticOrderLimitType !== item.logisticOrderLimitType) {
          messageApi.error(translate('You can only select items with the same logistic order limit type.'));
          return;
        }
      }
      setSelectedItems(prevItems => [...prevItems, item]);
    } else {
      setSelectedItems(prevItems => prevItems.filter(i => !((i.itemId === item.itemId) && (i.itemSpecId === item.itemSpecId))));
    }
  };

  const nextStep = () => {
    setCurrentStep(prevStep => (prevStep === Step.Logistic && userWantReservePay) ? Step.Confirm : prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(prevStep => (prevStep === Step.Confirm && userWantReservePay) ? Step.Logistic : prevStep - 1);
  };

  const renderItemList = (items: IShoppingCartItemViewModel[], logisticType: LogisticOrderLimitType) => {
    if (items.length === 0) {
      return null;
    }
    return (
      <div className="p-5" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <List
          bordered
          header={
            <Flex align="center" style={{ width: '100%' }}>
              <Checkbox
                onChange={(e) => handleSelectAll(items, e.target.checked)}
                checked={items.every(item => selectedItems.some(i => i.itemId === item.itemId && i.itemSpecId === item.itemSpecId))}
                disabled={logisticType === LogisticOrderLimitType.None ? isFrozenSelected : isNormalSelected}
                style={{ marginRight: '16px' }}
              >
                {translate('All')}
              </Checkbox>
              <Text strong style={{ flex: 1, textAlign: 'center' }} onClick={() => handleSelectAll(items, !items.every(item => selectedItems.some(i => i.itemId === item.itemId && i.itemSpecId === item.itemSpecId)))}>
                {logisticType === LogisticOrderLimitType.None ?
                  translate('Normal Logistics') :
                  translate('Frozen Logistics')}
              </Text>
            </Flex>
          }
          style={{
            width: `${listWidth}%`,
            opacity: logisticType === LogisticOrderLimitType.None ? (isFrozenSelected ? 0.5 : 1) : (isNormalSelected ? 0.5 : 1)
          }}
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item: IShoppingCartItemViewModel) => (
            <div
              onClick={() => handleSelectItem(item, !selectedItems.some(i => i.itemId === item.itemId && i.itemSpecId === item.itemSpecId))}
              style={{ cursor: 'pointer' }}
            >
              <List.Item
                actions={[
                  <Button type="primary" danger onClick={(e) => {
                    e.stopPropagation();
                    RemoveItemFromShoppingCart(item, refetch, handleSelectItem, messageApi, tryGetSessionId, isLogin, merchantId, translate, refreshShoppingCartItemCount, sendGAEvent, merchantPortalOptionSetting)
                  }}>
                    {translate('Delete')}
                  </Button>,
                ]}
              >
                <Checkbox
                  className="myCheckbox"
                  checked={selectedItems.some(i => i.itemId === item.itemId && i.itemSpecId === item.itemSpecId)}
                  onChange={(e) => { e.stopPropagation(); handleSelectItem(item, e.target.checked) }}
                  disabled={logisticType === LogisticOrderLimitType.None ? isFrozenSelected : isNormalSelected}
                />
                <img
                  src={item.itemIcon ?? imageNone}
                  style={{ minWidth: '100px', width: '100px', minHeight: '100px', height: '100px' }}
                />
                <List.Item.Meta
                  style={{ margin: '10px' }}
                  title={<Typography.Text strong>{translate('ItemName')}: {item.itemName}</Typography.Text>}
                  description={<>
                    <div>{translate('SpecName')}: {item.itemSpecName}</div>
                    <div>{translate('Sell price')}: {item.currency} {item.itemSpecPrice}</div>
                    <div>{translate('Buy amount')}: {item.buyAmount}</div>
                  </>}
                />
              </List.Item>
            </div>
          )}
          footer={(logisticType === LogisticOrderLimitType.None && isFrozenSelected) || (logisticType === LogisticOrderLimitType.Frozen && isNormalSelected) ? (
            <Text type="warning" style={{ display: 'block', textAlign: 'center', margin: '10px 0' }}>
              {getLogisticsWarning()}
            </Text>
          ) : null}
        />
      </div>
    )
  };

  const renderSelectItemStep = () => (
    <>
      {renderItemList(normalItems, LogisticOrderLimitType.None)}
      {renderItemList(frozenItems, LogisticOrderLimitType.Frozen)}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
        <Button shape="round" type="primary" disabled={selectedItems.length === 0} onClick={nextStep}>
          {translate('Next Step')}
        </Button>
      </div>
    </>
  );

  const noItemsView = () => {
    return <Empty style={{ margin: '30px' }} description={translate('No items found')}>
      <Button type="primary" onClick={() => { navigate(`${BASE_PATH}/`) }}>
        {translate('Go shopping')}
      </Button>
    </Empty>
  }

  return isLoading ? (
    <Empty style={{ margin: '30px' }}>
      <Spin tip="Loading..."></Spin>
    </Empty>
  ) : (data?.result === null || data?.result?.shoppingCartItemViewModels.length === 0) ? (
    noItemsView()
  ) : (
    <>
      <Flex align="center" justify="center">
        <Steps current={currentStep} items={stepItems} style={{ margin: 30, width: '80%' }} direction={deviceType === DeviceType.Mobile ? 'vertical' : 'horizontal'} />
      </Flex>
      {renderCurrentStep(
        currentStep, data,
        listWidth, selectedItems,
        handleSelectItem,
        nextStep, prevStep,
        selectedLogisticOption,
        logisticValues, userWantReservePay,
        selectedPayment, paymentValues,
        receiverInfos, translate,
        messageApi, tryGetSessionId,
        isLogin, merchantId,
        refetch,
        refreshShoppingCartItemCount,
        sendGAEvent,
        merchantPortalOptionSetting,
        renderSelectItemStep
      )}
    </>
  );
};

const useShoppingCartData = (merchantId, isLogin, tryGetSessionId) => {
  return useQuery(
    "GetShoppingCartApi",
    async () => isLogin ? await useGetShoppingCartApi(tryGetSessionId()) : await useGetTempShoppingCartApi(merchantId, tryGetSessionId()),
    {
      enabled: true,
    }
  );
};

const loadMemberInfo = async (setReceiverInfos) => {
  const response = await useGetMemberInfoApi();
  if (response.isSuccess) {
    setReceiverInfos({
      receiverName: response.result?.userName || '',
      receiverPhone: response.result?.phoneNumber || '',
      receiverEmail: response.result?.email || ''
    });
  }
};

const getSessionSelectedShoppingCartOption = (data, setSelectedItems, setReceiverInfos, setCurrentStep, localStorageHelper: LocalStorageHelper) => {
  const selectedShoppingCartOption = localStorageHelper.getWithExpiry<IShoppingCartItemViewModel[]>("selectedIShoppingCartItemViewModels");
  const filledReceiverInfos = localStorageHelper.getWithExpiry<IReceiverInfos>("filledReceiverInfos");
  if (selectedShoppingCartOption && filledReceiverInfos) {
    setSelectedItems(selectedShoppingCartOption.filter(item => data?.result?.shoppingCartItemViewModels?.some(i => i.itemId === item.itemId && i.itemSpecId === item.itemSpecId)));
    setReceiverInfos(filledReceiverInfos);
    setCurrentStep(Step.Logistic);
    localStorageHelper.setWithExpiry("selectedIShoppingCartItemViewModels", [], 0);
    localStorageHelper.setWithExpiry("filledReceiverInfos", {}, 0);
  }
};

const generateStepItems = (userWantReservePay, translate) => {
  if (userWantReservePay) {
    return [
      {
        title: translate(`Select Item`),
        icon: <ShoppingCartOutlined />,
      },
      {
        title: translate('Logistic'),
        icon: <ShoppingOutlined />,
      },
      {
        title: translate('Confirm'),
        icon: <CheckCircleOutlined />
      },
    ];
  } else {
    return [
      {
        title: translate(`Select Item`),
        icon: <ShoppingCartOutlined />,
      },
      {
        title: translate('Logistic'),
        icon: <ShoppingOutlined />,
      },
      {
        title: translate('Payment'),
        icon: <TransactionOutlined />,
      },
      {
        title: translate('Confirm'),
        icon: <CheckCircleOutlined />
      },
    ];
  }
};

const renderCurrentStep = (
  currentStep,
  data,
  listWidth,
  selectedItems,
  handleSelectItem,
  nextStep,
  prevStep,
  selectedLogisticOption,
  logisticValues,
  userWantReservePay,
  selectedPayment,
  paymentValues,
  receiverInfos,
  translate,
  messageApi,
  tryGetSessionId,
  isLogin,
  merchantId,
  refetch,
  refreshShoppingCartItemCount,
  sendGAEvent,
  merchantPortalOptionSetting,
  renderSelectItemStep
) => {
  switch (currentStep) {
    case Step.SelectItem:
      return renderSelectItemStep();
    case Step.Logistic:
      return <LogisticSelector nextStep={nextStep} prevStep={prevStep} />;
    case Step.Payment:
      return <PaymentSelector nextStep={nextStep} prevStep={prevStep} />;
    case Step.Confirm:
      return (
        <CheckoutOrder
          prevStep={prevStep}
          selectedShoppingCartItems={selectedItems}
          selectedMemberLogisticOption={selectedLogisticOption}
          logisticValues={logisticValues}
          userWantReservePay={userWantReservePay}
          selectedPayment={selectedPayment}
          paymentValues={paymentValues}
          receiverInfos={receiverInfos}
        />
      );
    default:
      return null;
  }
};

const RemoveItemFromShoppingCart = async (itemViewModel, refetch, handleSelectItem, messageApi, tryGetSessionId, isLogin, merchantId, translate, refreshShoppingCartItemCount, sendGAEvent, merchantPortalOptionSetting) => {
  const shoppingCartItems = [{ ...itemViewModel }];
  if (isLogin) {
    const request: IRemoveItemsToShoppingCartRequest = { shoppingCartItems };
    const response = await useRemoveItemsToShoppingCartApi(request);
    if (response.isSuccess) {
      messageApi.success(translate('Operation success'));
      sendGAEvent('remove_from_cart', {
        currency: shoppingCartItems[0].currency,
        value: shoppingCartItems.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
        items: shoppingCartItems.map((itemSpec) => {
          return {
            item_id: itemSpec?.itemId.toString(),
            item_name: itemSpec?.itemName,
            affiliation: merchantPortalOptionSetting?.merchantName,
            coupon: "None",
            discount: 0,
            index: 0,
            item_brand: merchantPortalOptionSetting?.merchantName,
            item_category: itemSpec!.itemSpecName,
            price: itemSpec!.itemSpecPrice,
            quantity: itemSpec.buyAmount
          }
        }),
      })
      handleSelectItem(itemViewModel, false);
      refetch();
    } else {
      messageApi.error(translate(response.message || 'Operation failed'));
    }
  }
  else {
    const tempRequest: IRemoveItemsFromTempShoppingCartRequest = {
      sessionId: tryGetSessionId(),
      merchantId: merchantId,
      shoppingCartItems
    };
    const response = await useRemoveItemsFromTempShoppingCartApi(tempRequest);
    if (response.isSuccess) {
      messageApi.success(translate('Operation success'));
      sendGAEvent('remove_from_cart', {
        currency: shoppingCartItems[0].currency,
        value: shoppingCartItems.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
        items: shoppingCartItems.map((itemSpec) => {
          return {
            item_id: itemSpec?.itemId.toString(),
            item_name: itemSpec?.itemName,
            affiliation: merchantPortalOptionSetting?.merchantName,
            coupon: "None",
            discount: 0,
            index: 0,
            item_brand: merchantPortalOptionSetting?.merchantName,
            item_category: itemSpec!.itemSpecName,
            price: itemSpec!.itemSpecPrice,
            quantity: itemSpec.buyAmount
          }
        }),
      })
      handleSelectItem(itemViewModel, false);
      refetch();
    } else {
      messageApi.error(translate(response.message || 'Operation failed'));
    }
  }
  refreshShoppingCartItemCount(isLogin);
};

export default ShoppingCartPage;
