
export enum LogisticOrderLimitType {
    /// <summary>
    /// 無
    /// </summary>
    None = 'None',
    /// <summary>
    /// 冷凍訂單
    /// </summary>
    Frozen = 'Frozen'
}