import { DeviceType } from "@/Templates/enums/templateEnums";
import { IPortalMerchantThirdPartyInvoiceSettingViewModel } from "@/interfaces/Responses";
import { useGetMerchantThirdPartyInvoiceSettingViewModes } from "@/lib/api/Portal/thirdPartyInovice";
import { useGetSupportedCountriesApi } from "@/lib/api/apis";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Empty, Flex, Form, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { CheckOutContext } from "../Contexts/ICheckOutContext";
import TaiwanInvoiceFields from "./TaiwanInvoiceFields";

export interface IInvoiceTeampleProps {

}
export interface IInvoiceTeampleRef {

}

const InvoiceTemplate = React.forwardRef((props: IInvoiceTeampleProps, ref: React.ForwardedRef<IInvoiceTeampleRef | undefined>) => {
    const { deviceType, merchantId } = useContext(GlobalContext);
    const {
        invoiceCountry, setInvoiceCountry,
        invoiceValues, setInvoiceValues,
        invoiceValidate, setInvoiceValidate
    } = useContext(CheckOutContext);
    const { translate, getCountrySelectionDisplayName } = useContext(TranslationContext);
    const { data } = useQuery('getSupportedCountries', async () => await useGetSupportedCountriesApi(), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setSupportedCountries(response.result);
            }
            mutate(merchantId);
        }
    });
    const [supportedCountries, setSupportedCountries] = useState<string[]>([]);
    const { mutate } = useMutation(async (merchantId: BigInt) => await useGetMerchantThirdPartyInvoiceSettingViewModes(merchantId), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setMerchantThirdPartyInvoiceSettingViewModels(response.result);
            }
        }
    });
    const [merchantThirdPartyInvoiceSettingViewModels, setMerchantThirdPartyInvoiceSettingViewModels] = useState<IPortalMerchantThirdPartyInvoiceSettingViewModel[]>([])
    const [merchantSupportedCountry, setMerchantSupportedCountry] = useState<string[]>([]);
    const getInvoiceTemplate = (country: string) => {
        switch (country) {
            case "TW":
                return (
                    //TW invoice
                    <TaiwanInvoiceFields></TaiwanInvoiceFields>
                );
            default:
                return (
                    //Unsupport country show antd no data
                    <Empty></Empty>
                );
        }
    }
    const [formWidth, setFormWidth] = useState('50%');

    useEffect(() => {
        if (supportedCountries && merchantThirdPartyInvoiceSettingViewModels && merchantThirdPartyInvoiceSettingViewModels?.length > 0) {
            var merchantSettingCounties = merchantThirdPartyInvoiceSettingViewModels.map(r => r.supportedCountry);
            var merchantSupportedCountry = supportedCountries.filter(r => merchantSettingCounties.includes(r));
            setMerchantSupportedCountry(merchantSupportedCountry);
        }
    }, [merchantThirdPartyInvoiceSettingViewModels]);

    useEffect(() => {
        switch (deviceType) {
            case DeviceType.Desktop:
            case DeviceType.LapTop:
                setFormWidth('40%');
                break;
            case DeviceType.Mobile:
                setFormWidth('90%');
                break;
        }
    }, [deviceType]);

    return (
        <div style={{ margin: '10px' }}>
            <Flex justify="center">
                <h3>{translate('Invoice')}</h3>
            </Flex>
            <Flex justify="center">
                <Form style={{ width: `${formWidth}` }}>
                    <Form.Item label={`${translate('Country')}`}>
                        <Select onChange={(value) => setInvoiceCountry(value)}>
                            {
                                merchantSupportedCountry.filter(r => r !== 'Unset').map((country) => {
                                    return (
                                        <Select.Option
                                            key={country}
                                            value={country}>
                                            {getCountrySelectionDisplayName(country)}
                                        </Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Flex>
            {getInvoiceTemplate(invoiceCountry)}
            <Flex style={{ margin: '10px' }} justify="center">
                {invoiceValidate ? null : <div style={{ color: 'red' }}>Please fill in all required fields</div>}
            </Flex>
        </div>
    );
});
export default InvoiceTemplate;