import { domainSuffixes, getCurrentDomain } from '@/constants/routes';

export class GlobalVars {
    static async getMerchantId() {
        const url = new URL(window.location.href);
        let merchantId = '';
        let domainHost = getCurrentDomain();

        // 如果当前域名不在 domainSuffixes 中，通过 API 获取 merchantId
        if (!domainSuffixes.includes(domainHost)) {
            try {
                const response = await fetch(`${process.env.BASE_API_URL}/api/Portal/GetMerchantIdByDomain?domain=${encodeURIComponent(`https://${domainHost}`)}`);
                if (!response.ok) {
                    console.error('Failed to fetch merchant ID:', response.statusText);
                    return '';
                }
                const data = await response.json();
                if (!data.isSuccess) {
                    console.error('Failed to fetch merchant ID:', data.message);
                    return '';
                }
                return data.result;
            } catch (error) {
                console.error('Error fetching merchant ID:', error);
                return ''; // 需要时返回空字符串或处理错误
            }
        }

        // 如果当前域名在 domainSuffixes 中，从 URL 路径中提取 merchantId
        const pathSegments = url.pathname.split('/');
        merchantId = pathSegments[1];
        return merchantId;
    }
}
