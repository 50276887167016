
import LineCallbackPage from "@/lib/thirdParty/LineCallbackPage";
import AnonymousOrderPages from '@/pages/AnonymousOrderPages/AnonymousOrderPages';
import DisclaimerPage from '@/pages/DisclaimerPages/DisclaimerPage';
import ItemDetailPage from "@/pages/ItemDetailPages/ItemDetailPage";
import ProxyLoginPage from '@/pages/MemberPages/ProxyLoginPage';
import PageFormPost from '@/pages/RedirectPages/PageFormPost';
import SearchPage from '@/pages/SearchPages/SearchPage';
import { CheckOutProvider } from '@/pages/ShoppingCartPages/Contexts/ICheckOutContext';
import InvoiceTemplate from '@/pages/ShoppingCartPages/InvoiceTemplates/InvoiceTemplate';
import ShoppingCartPage from '@/pages/ShoppingCartPages/ShoppingCartPage';
import { Result } from 'antd';
import { IRoute } from '../interfaces/Router';
import HomePage from "../pages/HomePage/HomePage";
import LandingPage from "../pages/LandingPages/LandingPage";
import LoginPage from "../pages/MemberPages/LoginPage";
import MemberInfoPage from "../pages/MemberPages/MemberInfoPage";
import Register from "../pages/MemberPages/Register";
import NotFoundPage from "../pages/NotFoundPages/NotFoundPage";

//MicroShop Free Domain
export const domainSuffixes = ["portal.junxiaotech.com", "localhost", "portal.localhost"];
export const getCurrentDomain = () => {
    if (typeof window !== 'undefined') {
        return window.location.hostname;
    }
    return '';  // Default value if `window` is not defined (e.g., during server-side rendering)
};
const domainMatchesAnySuffix = (currentDomain: string, suffixes: string[]) => {
    return suffixes.some(suffix => currentDomain.endsWith(suffix));
}

const currentDomain = getCurrentDomain();
export const BASE_PATH = domainMatchesAnySuffix(currentDomain, domainSuffixes)
    ? `/${window.location.pathname.split('/')[1] || 'unknown'}`
    : '';
export const BASE_WEBSOCKET_PATH =
    domainMatchesAnySuffix(currentDomain, domainSuffixes)
        ? `${currentDomain}/${window.location.pathname.split('/')[1] || 'unknown'}`
        : '';

//BASE_PATH is required if you use free domain (MicroShop)
const routes: { [key: string]: IRoute } = {
    MerchantClosedPage: { path: `${BASE_PATH}/merchantClosed`, element: <NotFoundPage /> },
    HomePage: { path: `${BASE_PATH}/`, element: <HomePage /> },
    LandingPage: { path: `${BASE_PATH}/landing`, element: <LandingPage />, },
    MemberInfoPage: { path: `${BASE_PATH}/memberInfo`, element: <MemberInfoPage /> },
    LoginPage: { path: `${BASE_PATH}/login`, element: <LoginPage /> },
    LineCallbackPage: { path: `/line-login-callback`, element: <LineCallbackPage />, isGlobalPage: true },
    ProxyLoginPage: { path: `${BASE_PATH}/proxy-login`, element: <ProxyLoginPage />, isGlobalPage: true },
    RegisterPage: { path: `${BASE_PATH}/register`, element: <Register /> },
    ItemDetailPage: { path: `${BASE_PATH}/itemDetail`, element: <ItemDetailPage /> },
    SearchPage: {
        path: `${BASE_PATH}/searchPage`,
        element: <SearchPage />
    },
    ShoppingCartPage: {
        path: `${BASE_PATH}/shoppingCart`, element: <CheckOutProvider>
            <ShoppingCartPage />
        </CheckOutProvider>
    },
    PageFormPost: { path: `${BASE_PATH}/pageFormPost`, element: <PageFormPost /> },
    NotFoundPage: { path: `${BASE_PATH}/*`, element: <NotFoundPage /> },
    DisclaimerPage: { path: `/disclaimer`, element: <DisclaimerPage /> },
    AnonymousOrderPage: { path: `${BASE_PATH}/anonymousOrder`, element: <AnonymousOrderPages /> },
    DevPage: {
        path: `${BASE_PATH}/dev`, element: <CheckOutProvider>
            <InvoiceTemplate></InvoiceTemplate>
        </CheckOutProvider>
    },
    ErrorPage: {
        path: `/error`,
        element: <Result
            status='error' />,
        isGlobalPage: true
    }
}

export default routes;