import { Button } from 'antd';
import queryString from 'query-string';
import { useEffect } from 'react';
import lineIcon from '../../assets/Line/LINE_Brand_icon.png';

export interface ILineLoginProps {
    tokenCallback: (token: string) => void;
}

const LineLogin = (props: ILineLoginProps) => {
    const clientId = process.env.LineClientId;
    const redirectUri = `${process.env.LineRedirectUri}`;
    const state = window.location.origin !== new URL(redirectUri).origin ? `${encodeURI(window.location.origin)}` : `${encodeURI(window.location.href.replace('/login', ''))}`;
    const scope = 'profile%20openid%20email'; // Scopes you want to request
    const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;

    useEffect(() => {
        const handleLineLogin = async () => {
            const { token, method } = queryString.parse(window.location.search);
            if (method === 'line' && token) {
                try {
                    props.tokenCallback?.(token as string);
                } catch (error) {
                    console.error('Error during LINE login:', error);
                }
            }
        };

        handleLineLogin();
    }, []);

    return (
        <div>
            <Button
                icon={<img width={20} src={lineIcon}></img>}
                onClick={() => {
                    window.location.href = lineLoginUrl
                }}>
                LINE
            </Button>
        </div>
    );
};

export default LineLogin;
