import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { NavBarTemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import LoadingComponent from "./LoadingCompoent";

const NavBar: React.FC<NavBarTemplateProps> = React.memo(({ merchantId, deviceType, portalOptionSettingViewModel, portalStyleSettings, translate, messageApi, config, BASE_PATH, menuItems, isHideNavBar, merchantPortalOptionSetting, navBarHeight, shoppingCartItemCount, isLogin }) => {
    const portalStyle = portalStyleSettings.find(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'NavBar');

    const TemplateComponent = useMemo(() => {
        return dynamic<NavBarTemplateProps>(() =>
            import(`../../Templates/Shop/Components/NavBar/Template_${portalStyle?.style || '0'}.tsx`),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, [portalStyle]);

    return <TemplateComponent
        merchantId={merchantId}
        deviceType={deviceType}
        messageApi={messageApi}
        isPreviewMode={false}
        config={config}
        translate={translate}
        portalStyleSettings={portalStyleSettings}
        portalOptionSettingViewModel={portalOptionSettingViewModel}
        BASE_PATH={BASE_PATH}
        menuItems={menuItems}
        isHideNavBar={isHideNavBar}
        merchantPortalOptionSetting={merchantPortalOptionSetting}
        navBarHeight={navBarHeight}
        shoppingCartItemCount={shoppingCartItemCount}
        isLogin={isLogin}
    />;
});

export default NavBar;
