import { ThirdPartyPaymentCreateResponseTypeEnum } from '@/enums/Enums';
import { IPortalMerchantThirdPartyPaymentSettingViewModel } from '@/interfaces/Responses';
import { useCreatePaymentOrder, useGetPaymentMainTypesApi, useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi } from '@/lib/api/apis';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { UrlHelper } from '@/lib/helpers/UrlHelper';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { Button, Col, Divider, Flex, Row, Tabs, Typography } from 'antd';
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useQuery } from 'react-query';
import { CheckOutContext } from '../Contexts/ICheckOutContext';
import './PaymentSelector.css';

const { TabPane } = Tabs;
const { Title } = Typography;

export interface IPaymentSelectorProps {
    nextStep?: () => void;
    prevStep?: () => void;
    isHideSteps?: boolean | undefined;
    orderId?: BigInt;
}

export interface IPaymentSelectorRef {
    reset: () => void;
}

const PaymentSelector = React.forwardRef((props: IPaymentSelectorProps, ref: React.ForwardedRef<IPaymentSelectorRef | undefined>) => {
    const { messageApi, merchantId } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const {
        isOnlinePayment, setIsOnlinePayment,
        selectedPayment, setSelectedPayment,
        paymentValues, setPaymentValues,
        logiscticSupportedPaymentSettingIds
    } = useContext(CheckOutContext);
    const uRLHelper = new UrlHelper();
    const [portalMerchantPaymentSettings, setPortalMerchantPaymentSettings] = useState<IPortalMerchantThirdPartyPaymentSettingViewModel[]>([]);
    const [paymentMainTypes, setPaymentMainTypes] = useState<string[]>([]);

    const { refetch: refetchPaymentSettings } = useQuery('getMerchantThirdPartyPaymentSettingViewModels',
        async () => await useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi(merchantId), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                if (logiscticSupportedPaymentSettingIds.length > 0) {
                    setPortalMerchantPaymentSettings(response.result.filter(payment =>
                        logiscticSupportedPaymentSettingIds.map(r => r.toString()).includes(payment.thirdPartySettingId.toString())));
                } else {
                    setPortalMerchantPaymentSettings(response.result);
                }
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { refetch: refetchPaymentMainTypes, isLoading } = useQuery('getPaymentMainTypes',
        async () => await useGetPaymentMainTypesApi(), {
        onSuccess: (response) => {
            if (response.isSuccess && response.result) {
                setPaymentMainTypes(response.result);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    useEffect(() => {
        refetchPaymentSettings();
        refetchPaymentMainTypes();
    }, []);

    const renderPaymentMethods = () => {
        if (isLoading) return <LoadingComponent></LoadingComponent>;

        if (!portalMerchantPaymentSettings || !paymentMainTypes) return null;

        return (
            <div>
                {paymentMainTypes
                    .filter(paymentMainType =>
                        portalMerchantPaymentSettings.some(payment => payment.paymentMainType === paymentMainType) &&
                        paymentMainType !== 'None')
                    .map((paymentMainType) => (
                        <React.Fragment key={paymentMainType}>
                            <Divider>{translate(paymentMainType)}</Divider>
                            <Row justify="center" gutter={[16, 16]}>
                                <Col span={24}>
                                    <Flex justify='center' align='center' gap={'small'} wrap='wrap'>
                                        {portalMerchantPaymentSettings
                                            .filter(payment => payment.paymentMainType === paymentMainType)
                                            .map((payment) => (
                                                <Button
                                                    key={payment.thirdPartySettingId.toString()}
                                                    type={payment === selectedPayment ? 'primary' : 'default'}
                                                    onClick={() => {
                                                        setSelectedPayment(payment);
                                                        setPaymentValues({});
                                                    }}
                                                >
                                                    {payment.providerName}
                                                </Button>
                                            ))}
                                    </Flex>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
            </div>
        );
    };

    const previousStepBtn = () => {
        setSelectedPayment(undefined);
        setPaymentValues({});
        props.prevStep && props.prevStep();
    }

    const nextBtn = () => {
        props.nextStep && props.nextStep();
    }

    const validateRequest = (): boolean => {
        return !!(selectedPayment && props.orderId);
    }

    const sendPaymentRequest = async () => {
        if (!validateRequest()) {
            return;
        }

        const frontEndValues = { ...paymentValues, ['FrontEndHostDomain']: `${window.location.protocol}//${window.location.host}` };
        const response = await useCreatePaymentOrder({
            orderId: props.orderId!,
            settingId: selectedPayment!.thirdPartySettingId,
            frontEndValues: frontEndValues
        });

        if (!response.isSuccess) {
            messageApi.error("Failed to send payment request");
            return;
        }

        messageApi.success("Send payment request success");
        switch (response.result?.thirdPartyPaymentCreateResponseTypeEnum) {
            case ThirdPartyPaymentCreateResponseTypeEnum.FormPost:
                const dic = JSONBig.parse(response.result?.data);
                uRLHelper.pageFormPost(response.result?.url, dic);
                break;
            case ThirdPartyPaymentCreateResponseTypeEnum.Html:
                // Handle HTML response
                break;
            case ThirdPartyPaymentCreateResponseTypeEnum.QRCode:
                // Handle QR Code response
                break;
            case ThirdPartyPaymentCreateResponseTypeEnum.Redirect:
                // Handle Redirect response
                break;
        }
    }

    useImperativeHandle(ref, () => ({
        reset: () => {
            setSelectedPayment(undefined);
            setPaymentValues({});
        }
    }), []);

    return (
        <Flex justify="center">
            <Row style={{ width: '80%' }}>
                <Col span={24}>
                    <Tabs centered defaultActiveKey="1" onChange={(key) => setIsOnlinePayment(key === '1')}>
                        <TabPane tab={translate('Online Payment')} key="1">
                            {renderPaymentMethods()}
                        </TabPane>
                        {/* <TabPane tab={translate('Offline Payment')} key="2">
                            <div>{translate('Please contact our support team for offline payment options.')}</div>
                        </TabPane> */}
                    </Tabs>
                </Col>
                <Col style={{ marginTop: '20px' }} xs={24}>
                    {!props.isHideSteps && (
                        <Flex justify="space-between">
                            <Button shape="round" danger onClick={previousStepBtn}>
                                {translate('Previous Step')}
                            </Button>
                            <Button shape="round" type="primary" disabled={!selectedPayment} onClick={nextBtn}>
                                {translate('Next Step')}
                            </Button>
                        </Flex>
                    )}
                    {props.orderId && (
                        <Flex justify="center" style={{ margin: '10px' }}>
                            <Button shape="round" type="primary" disabled={!validateRequest()} onClick={sendPaymentRequest}>
                                {translate('Send Payment Request')}
                            </Button>
                        </Flex>
                    )}
                </Col>
            </Row>
        </Flex>
    );
});

export default PaymentSelector;