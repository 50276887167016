import React, { useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from '../../../constants/routes';
import { GlobalContext } from "../../../lib/contexts/GlobalContext";

const MyRoutes = () => {
  const { navBarGap, sendGAEvent } = React.useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    sendGAEvent('page_view', { page_path: `${location.pathname}` });
  }, [location.pathname]);

  const memoizedRoutes = useMemo(() => (
    <div style={{ paddingTop: `${navBarGap}px` }}>
      <Routes>
        {Object.values(routes).map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </div>
  ), [navBarGap]);

  return memoizedRoutes;
};

export default MyRoutes;
