export enum LoginTypeEnum {
    /// <summary>
    /// 信箱
    /// </summary>
    Email = "Email",
    /// <summary>
    /// 手機
    /// </summary>
    PhoneNumber = "PhoneNumber",
}

export enum RegistTypeEnum {
    /// <summary>
    /// 信箱
    /// </summary>
    Email = "Email",
    /// <summary>
    /// 手機
    /// </summary>
    PhoneNumber = "PhoneNumber",
}

export enum GenderEnum {
    Unset = "Unset",
    Male = "Male",
    Female = "Female"
}

export enum OrderStatus {
    WaitForPaid = 'WaitForPaid',
    Paid = 'Paid',
    WaitForMerchantConfirm = 'WaitForMerchantConfirm',
    WaitForShipment = 'WaitForShipment',
    DuringShipment = 'DuringShipment',
    ShipmentDispute = 'ShipmentDispute',
    WaitForBuyerPickup = 'WaitForBuyerPickup',
    BuyerNotPickup = 'BuyerNotPickup',
    ReturnToLogisticsCenter = 'ReturnToLogisticsCenter',
    BuyerApplyRefund = 'BuyerApplyRefund',
    WaitForMerchantConfirmRefundApplication = 'WaitForMerchantConfirmRefundApplication',
    WaitForReturnShipment = 'WaitForReturnShipment',
    DuringReturnShipment = 'DuringReturnShipment',
    ReturnShipmentDispute = 'ReturnShipmentDispute',
    WaitForMerchantPickupRefund = 'WaitForMerchantPickupRefund',
    MerchantNotPickup = 'MerchantNotPickup',
    RefundReturnToLogisticsCenter = 'RefundReturnToLogisticsCenter',
    WaitForMerchantConfirmRefundItems = 'WaitForMerchantConfirmRefundItems',
    WaitForMerchantRefundAmount = 'WaitForMerchantRefundAmount',
    RefundDone = 'RefundDone',
    RefundUnderDispute = 'RefundUnderDispute',
    Done = 'Done',
    Cancel = 'Cancel',
    Unknow = 'Unknow'
}

export enum LogisticReceviePointType {
    HomeDelivery = 'HomeDelivery',
    ConvenienceStore = 'ConvenienceStore',
    ProviderLocalStore = 'ProviderLocalStore',
}

export enum LogisticSenderPointType {
    HomeDelivery = 'HomeDelivery',
    ConvenienceStore = 'ConvenienceStore',
    ProviderLocalStore = 'ProviderLocalStore',
}

export enum FrontEndDisplayType {
    /// <summary>
    /// 頁面跳轉
    /// </summary>
    PageRedirectURL = 'PageRedirectURL',
    /// <summary>
    /// 頁面FormPost組合打出
    /// </summary>
    PageFormPost = 'PageFormPost',
    /// <summary>
    /// 新開頁面
    /// </summary>
    OpenNewPage = 'OpenNewPage',
    Html = 'Html',
}

//國家編碼
export enum ISO3166_1Alpha2 {
    TW = 'TW',
    CN = 'CN',
    HK = 'HK',
    US = 'US',
    MY = 'MY',
    PH = 'PH',
    JP = 'JP',
}

export enum ThirdPartyPaymentCreateResponseTypeEnum {
    FormPost = "FormPost",
    Redirect = "Redirect",
    QRCode = "QRCode",
    Html = "Html"
}

export enum LogisticsMainType {
    None = 'None',
    AllInOneIntegration = 'AllInOneIntegration',
    ConvenienceStore = 'ConvenienceStore',
    HomeDelivery = 'HomeDelivery',
    MerchantHandle = 'MerchantHandle',
}

export enum ThirdPartyLoginProviders {
    Unset = 'Unset',
    Google = 'Google',
    Facebook = 'Facebook',
    Twitter = 'Twitter',
    Line = 'Line',
}

export enum ThirdPartyMessageGroupProvider {
    Facebook = 'Facebook',
    Line = 'Line',
}

export enum MemberPassOTPVerifyType {
    Email,
    PhoneNumber
}