import { BASE_PATH } from '@/constants/routes';
import { ICheckOutRequest, ICheckOutWithOutRegistRequest } from '@/interfaces/Requests';
import { IMemberLogisticOption, IPortalMerchantThirdPartyPaymentSettingViewModel, IShoppingCartItem } from '@/interfaces/Responses';
import { IReceiverInfos } from '@/interfaces/VIewModels';
import { useCheckOutApi, useCheckOutOrderWithAllowNoRegistApi, useCreatePaymentOrder } from '@/lib/api/apis';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { SignHelper } from "@/lib/helpers/SignHelper";
import { MerchantCheckoutType } from '@/Templates/enums/templateEnums';
import { Button, Col, Collapse, Divider, Flex, List, Row, Typography } from 'antd';
import JSONBig from "json-bigint";
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import imageNone from '../../../assets/None.png';
import { GlobalContext } from '../../../lib/contexts/GlobalContext';
import { CheckOutContext } from '../Contexts/ICheckOutContext';
import './CheckoutOrder.css';
import InvoiceSelector from './InvoiceSelector';

const { Panel } = Collapse;

export interface ICheckoutOrderProps {
    prevStep: () => void;
    selectedShoppingCartItems: IShoppingCartItem[];
    selectedMemberLogisticOption: IMemberLogisticOption | undefined;
    logisticValues: { [key: string]: string } | undefined;
    userWantReservePay: boolean;
    selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    paymentValues: { [key: string]: string } | undefined;
    receiverInfos: IReceiverInfos;
}

const CheckoutOrder: React.FC<ICheckoutOrderProps> = ({
    prevStep,
    selectedShoppingCartItems,
    selectedMemberLogisticOption,
    logisticValues,
    userWantReservePay,
    selectedPayment,
    paymentValues,
    receiverInfos }) => {
    const navigate = useNavigate();
    const {
        isOnlinePayment,
        selectedLogistic,
        invoiceCountry,
        invoiceValues, setInvoiceValues,
        invoiceValidate, setInvoiceValidate
    } = useContext(CheckOutContext);
    const { translate } = useContext(TranslationContext);
    const {
        sendGAEvent,
        messageApi,
        isLogin,
        merchantId,
        tryGetSessionId,
        merchantPortalOptionSetting
    } = useContext(GlobalContext);
    const signHelper = new SignHelper();

    const handlePreviousStep = () => {
        prevStep();
    };

    const handleSendGAGroupEvent = (transactionId: string) => {
        sendGAEvent('add_shipping_info', {
            currency: selectedShoppingCartItems[0].currency,
            value: selectedShoppingCartItems.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
            coupon: "None",
            shipping_tier: selectedLogistic?.providerName,
            items: selectedShoppingCartItems.map((itemSpec, index) => {
                return {
                    item_id: itemSpec?.itemId.toString(),
                    item_name: itemSpec?.itemName,
                    affiliation: merchantPortalOptionSetting?.merchantName,
                    coupon: "None",
                    discount: 0,
                    index: 0,
                    item_brand: merchantPortalOptionSetting?.merchantName,
                    item_category: itemSpec!.itemSpecName,
                    price: itemSpec!.itemSpecPrice,
                    quantity: itemSpec.buyAmount
                }
            }),
        })

        sendGAEvent('add_payment_info', {
            currency: selectedShoppingCartItems[0].currency,
            value: selectedShoppingCartItems.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
            coupon: "None",
            payment_type: selectedPayment?.providerName,
            items: selectedShoppingCartItems.map((itemSpec, index) => {
                return {
                    item_id: itemSpec?.itemId.toString(),
                    item_name: itemSpec?.itemName,
                    affiliation: merchantPortalOptionSetting?.merchantName,
                    coupon: "None",
                    discount: 0,
                    index: 0,
                    item_brand: merchantPortalOptionSetting?.merchantName,
                    item_category: itemSpec!.itemSpecName,
                    price: itemSpec!.itemSpecPrice,
                    quantity: itemSpec.buyAmount
                }
            }),
        })

        sendGAEvent('purchase', {
            currency: selectedShoppingCartItems[0].currency,
            value: selectedShoppingCartItems.reduce((acc, item) => acc + item.itemSpecPrice * item.buyAmount, 0),
            transaction_id: transactionId,
            coupon: "None",
            items: selectedShoppingCartItems.map((itemSpec, index) => {
                return {
                    item_id: itemSpec?.itemId.toString(),
                    item_name: itemSpec?.itemName,
                    affiliation: merchantPortalOptionSetting?.merchantName,
                    coupon: "None",
                    discount: 0,
                    index: 0,
                    item_brand: merchantPortalOptionSetting?.merchantName,
                    item_category: itemSpec!.itemSpecName,
                    price: itemSpec!.itemSpecPrice,
                    quantity: itemSpec.buyAmount
                }
            }),
        })
    }

    const handleCheckOut = async () => {
        if (isLogin) {
            await checkOutNormal();
        } else if (merchantPortalOptionSetting?.merchantCheckoutType === MerchantCheckoutType.AllowNoneRegist) {
            await checkOutWithOutRegist();
        } else {
            messageApi.error("Please login first");
        }
    };

    const checkOutNormal = async () => {
        const request: ICheckOutRequest = {
            buyItems: selectedShoppingCartItems,
            merchantId: merchantId,
            isOnlinePayment: isOnlinePayment,
            thirdPaymentSettingId: selectedPayment ? selectedPayment.thirdPartySettingId : BigInt(0),
            thirdPartyLogistcSettingId: selectedLogistic ? selectedLogistic.id : BigInt(0),
            paymentValues: paymentValues || {},
            memberLogisticOptionId: selectedMemberLogisticOption!.id,
            logisticValues: logisticValues || {},
            invoiceCountry: invoiceCountry,
            invoiceValues: invoiceValues || {},
            orderValues: {}
        };
        addReceiverInfo(request);
        if (userWantReservePay) {
            request.logisticValues["IsCollection"] = 'true';
        } else {
            request.logisticValues["IsCollection"] = 'false';
        }
        const response = await useCheckOutApi(request);
        if (!response.isSuccess) {
            messageApi.error(translate("Operation failed. Please try again later."));
            window.location.reload();
        }

        handleSendGAGroupEvent(response.result!.toString());

        if (!userWantReservePay) {
            await createPaymentOrder(response.result!, request.thirdPaymentSettingId, request.paymentValues);
        } else {
            navigate(`${BASE_PATH}/memberInfo?orderId=${response.result}`);
        }
    };

    const checkOutWithOutRegist = async () => {
        const request: ICheckOutWithOutRegistRequest = {
            buyItems: selectedShoppingCartItems,
            merchantId: merchantId,
            sessionId: tryGetSessionId(),
            hash: "",
            isOnlinePayment: isOnlinePayment,
            thirdPaymentSettingId: selectedPayment ? selectedPayment.thirdPartySettingId : BigInt(0),
            thirdPartyLogistcSettingId: selectedLogistic ? selectedLogistic.id : BigInt(0),
            paymentValues: paymentValues || {},
            memberLogisticOptionId: selectedMemberLogisticOption!.id,
            logisticValues: logisticValues || {},
            invoiceCountry: invoiceCountry,
            invoiceValues: invoiceValues || {},
            orderValues: {}
        };
        addReceiverInfo(request);
        if (userWantReservePay) {
            request.logisticValues["IsCollection"] = 'true';
        } else {
            request.logisticValues["IsCollection"] = 'false';
        }
        const st = `${merchantId}:${tryGetSessionId()}:${JSONBig.stringify(selectedShoppingCartItems)}:${tryGetSessionId()}`;
        request.hash = await signHelper.generateDynamicTimeSignAsync(st, new Date());
        const response = await useCheckOutOrderWithAllowNoRegistApi(request);
        if (!response.isSuccess) {
            messageApi.error(translate("Operation failed. Please try again later."));
            window.location.reload();
        }

        handleSendGAGroupEvent(response.result!.toString());

        if (!userWantReservePay) {
            await createPaymentOrder(response.result!, request.thirdPaymentSettingId, request.paymentValues);
        } else {
            navigate(`${BASE_PATH}/anonymousOrder?orderId=${response.result}`);
        }
    };

    const createPaymentOrder = async (orderId: BigInt, settingId: BigInt, frontEndValues: { [key: string]: string }) => {
        const response = await useCreatePaymentOrder({
            orderId: orderId,
            settingId: settingId,
            frontEndValues: frontEndValues
        });

        if (!response.isSuccess) {
            messageApi.error(translate("Operation failed. Please try again later."));
            window.location.reload();
        } else {
            if (isLogin) {
                navigate(`/memberInfo?orderId=${response.result}`);
            } else {
                navigate(`/anonymousOrder?orderId=${response.result}`);
            }
        }
    };

    const addReceiverInfo = (request: ICheckOutRequest | ICheckOutWithOutRegistRequest) => {
        Object.entries(receiverInfos).forEach(([key, value]) => {
            request.orderValues[key] = value;
        });
    };

    return (
        <Flex justify={'center'}>
            <Row style={{ width: '80%' }}>
                <Col xs={24} md={12}>
                    <List style={{ width: '80%' }} itemLayout="horizontal">
                        {selectedShoppingCartItems.map(item => (
                            <List.Item key={uuidv4()}>
                                <img
                                    src={item.itemIcon ?? imageNone}
                                    style={{ margin: '5px', minWidth: '100px', width: '100px', minHeight: '100px', height: '100px' }}
                                />
                                <List.Item.Meta
                                    title={<Typography.Text strong>{translate('ItemName')}: {item.itemName}</Typography.Text>}
                                    description={
                                        <div>
                                            {`${translate('Spec')}${translate('Name')}: ${item.itemSpecName}`}
                                            <br />
                                            {`${translate('Price')}: ${item.itemSpecPrice}`}
                                            <br />
                                            {`${translate('Amount')}: ${item.buyAmount}`}
                                        </div>
                                    }
                                />
                            </List.Item>
                        ))}
                    </List>
                </Col>
                <Col xs={24} md={12}>
                    {selectedMemberLogisticOption && (
                        <>
                            <div>{translate('Logistic')}：{selectedMemberLogisticOption.displayName}</div>
                            <div>{translate('Address')}：{selectedMemberLogisticOption.displayAddress}</div>
                        </>
                    )}
                    <Divider />
                    {selectedPayment && (
                        <>
                            <div>{translate('Payment Methods')}：{selectedPayment.paymentMainType.toString()}</div>
                            <div>{translate('Provider Name')}：{selectedPayment.providerName.toString()}</div>
                        </>
                    )}
                </Col>
                <Col xs={24} style={{ margin: '10px' }}>
                    <InvoiceSelector />
                </Col>
                <Col xs={24}>
                    <Flex justify='space-between'>
                        <Button shape="round" danger onClick={handlePreviousStep}>
                            {translate('Previous Step')}
                        </Button>
                        <Button shape="round" type="primary" disabled={!invoiceValidate} onClick={handleCheckOut}>
                            {translate('Checkout')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </Flex>
    );
};

export default CheckoutOrder;
