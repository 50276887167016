import { IMemberLogisticOption, IPortalMerchantThirdPartyLogisticSettingViewModel, IPortalMerchantThirdPartyPaymentSettingViewModel, IShoppingCartItem } from "@/interfaces/Responses";
import { IReceiverInfos } from "@/interfaces/VIewModels";
import { ReactNode, createContext, useState } from "react";
import { Step } from "../ShoppingCartPage";

export interface ICheckOutContext {
    selectedItems: IShoppingCartItem[];
    setSelectedItems: React.Dispatch<React.SetStateAction<IShoppingCartItem[]>>;
    selectedLogistic: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined;
    setSelectedLogistic: React.Dispatch<React.SetStateAction<IPortalMerchantThirdPartyLogisticSettingViewModel | undefined>>;
    selectedLogisticOption: IMemberLogisticOption | undefined;
    setSelectedLogisticOption: React.Dispatch<React.SetStateAction<IMemberLogisticOption | undefined>>;
    isOnlinePayment: boolean;
    setIsOnlinePayment: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    setSelectedPayment: React.Dispatch<React.SetStateAction<IPortalMerchantThirdPartyPaymentSettingViewModel | undefined>>;
    currentStep: Step;
    setCurrentStep: React.Dispatch<React.SetStateAction<Step>>;
    userWantReservePay: boolean;
    setUserWantReservePay: React.Dispatch<React.SetStateAction<boolean>>;
    logisticValues: { [key: string]: string } | undefined;
    setLogisticValues: React.Dispatch<React.SetStateAction<{ [key: string]: string } | undefined>>;
    paymentValues: { [key: string]: string } | undefined;
    setPaymentValues: React.Dispatch<React.SetStateAction<{ [key: string]: string } | undefined>>;
    logiscticSupportedPaymentSettingIds: BigInt[];
    setLogiscticSupportedPaymentSettingIds: React.Dispatch<React.SetStateAction<BigInt[]>>;
    receiverInfos: IReceiverInfos;
    setReceiverInfos: React.Dispatch<React.SetStateAction<IReceiverInfos>>;
    invoiceCountry: string;
    setInvoiceCountry: React.Dispatch<React.SetStateAction<string>>;
    invoiceValues: Record<string, string>;
    setInvoiceValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    invoiceValidate: boolean;
    setInvoiceValidate: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CheckOutContext = createContext<ICheckOutContext>(null!);

export const CheckOutProvider = ({ children }: { children: ReactNode }) => {
    const [selectedItems, setSelectedItems] = useState<IShoppingCartItem[]>([]);
    const [selectedLogistic, setSelectedLogistic] = useState<IPortalMerchantThirdPartyLogisticSettingViewModel | undefined>(undefined);
    const [selectedLogisticOption, setSelectedLogisticOption] = useState<IMemberLogisticOption | undefined>(undefined);
    const [isOnlinePayment, setIsOnlinePayment] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<IPortalMerchantThirdPartyPaymentSettingViewModel | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState<Step>(Step.SelectItem);
    const [userWantReservePay, setUserWantReservePay] = useState<boolean>(false);
    const [logisticValues, setLogisticValues] = useState<{ [key: string]: string } | undefined>(undefined);
    const [paymentValues, setPaymentValues] = useState<{ [key: string]: string } | undefined>(undefined);
    const [logiscticSupportedPaymentSettingIds, setLogiscticSupportedPaymentSettingIds] = useState<BigInt[]>([]);
    const [receiverInfos, setReceiverInfos] = useState<IReceiverInfos>({
        receiverName: '',
        receiverPhone: '',
        receiverEmail: ''
    });
    const [invoiceCountry, setInvoiceCountry] = useState<string>('Unset');
    const [invoiceValues, setInvoiceValues] = useState<Record<string, string>>({});
    const [invoiceValidate, setInvoiceValidate] = useState<boolean>(false);

    return (
        <CheckOutContext.Provider
            value={{
                selectedItems,
                setSelectedItems,
                selectedLogistic,
                setSelectedLogistic,
                selectedLogisticOption,
                setSelectedLogisticOption,
                isOnlinePayment,
                setIsOnlinePayment,
                selectedPayment,
                setSelectedPayment,
                currentStep,
                setCurrentStep,
                userWantReservePay,
                setUserWantReservePay,
                logisticValues,
                setLogisticValues,
                paymentValues,
                setPaymentValues,
                logiscticSupportedPaymentSettingIds,
                setLogiscticSupportedPaymentSettingIds,
                receiverInfos,
                setReceiverInfos,
                invoiceCountry,
                setInvoiceCountry,
                invoiceValues,
                setInvoiceValues,
                invoiceValidate,
                setInvoiceValidate
            }}
        >
            {children}
        </CheckOutContext.Provider>
    );
};