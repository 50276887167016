import { ISO3166_1Alpha2 } from '@/enums/Enums';
import { AddressHelper } from '@/lib/helpers/AddressHelper';
import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';

const { Option } = Select;

export interface IAddAddressOptionProps {
    NewMemberLogisticOptionValuesEvnet: (optionValues: Record<string, string>) => void;
}

const AddAddressOption: React.FC<IAddAddressOptionProps> = ({ NewMemberLogisticOptionValuesEvnet }) => {
    const [visible, setVisible] = useState(false);
    const helper = new AddressHelper();
    const [addressDatas, SetAddressDatas] = useState<any>(undefined);
    const [address, setAddress] = useState("");
    const [selected, setSelected] = useState<Record<number, string>>({});
    const [eachLayerSelect, setEachLayerSelect] = useState<Record<number, any>>({});
    const [finalPostNumber, setFinalPostNumber] = useState<any>(undefined);
    const [showName, setShowName] = useState<any>(undefined);
    function handleCountryChange(value: ISO3166_1Alpha2) {
        resetFields();
        var options = helper.getAddressOptions(value);
        SetAddressDatas(options);
    }

    const renderSelect = (data: any, level = 0) => {
        if (typeof data !== 'object') {
            return <></>
        }
        var keys = Object.keys(data);
        if (keys.length === 0) {
            return <>
            </>;
        }
        const handleChange = (value: any) => {
            setSelected({ ...selected, [level]: value });
            setEachLayerSelect({ ...eachLayerSelect, [level]: data[value] });
        };
        return <div>
            {/*這層*/}
            <Form.Item
                name={`zone ${level}`}
                label={`zone ${level}`}
                rules={[{ required: true, message: 'required' }]}
            >
                <Select value={keys} onChange={handleChange}>
                    {Object.keys(data).map((key) => (
                        <Option key={key} value={key}>{key}</Option>
                    ))}
                </Select>
            </Form.Item>
            {/*下一層*/}
            {eachLayerSelect[level] && renderSelect(eachLayerSelect[level], level + 1)}
        </div>
    };

    function resetFields() {
        setSelected({});
        setEachLayerSelect({});
        setFinalPostNumber(undefined);
    };

    function Summit() {
        var fullyAddress = Object.values(selected).join('') + address;
        NewMemberLogisticOptionValuesEvnet({
            "Shows": showName,
            "Address": fullyAddress,
        });
    };

    useEffect(() => {
        // eachLayerSelect latest is string
        if (typeof eachLayerSelect[Object.keys(eachLayerSelect).length - 1] === 'string') {
            setFinalPostNumber(eachLayerSelect[Object.keys(eachLayerSelect).length - 1]);
        }
    }, [eachLayerSelect]);

    return (
        <>
            <Button onClick={() => setVisible(true)}>Add Address</Button>
            <Modal
                open={visible}
                onOk={() => {
                    Summit();
                    resetFields();
                    setVisible(false);
                }}
                okButtonProps={{ disabled: !finalPostNumber || !address || !showName }}
                onCancel={() => {
                    resetFields();
                    setVisible(false)
                }}
            >
                <Form>
                    <Form.Item
                        label="Custom Name"
                        name="showName"
                        rules={[{ required: true, message: 'Please input your custom name!' }]}>
                        <Input placeholder="Custom Name" value={showName} onChange={e => setShowName(e.target.value)} />
                    </Form.Item>
                </Form>
                <Form>
                    <Form.Item
                        name="Select a country"
                        label="Select a country"
                        rules={[{ required: true, message: 'required' }]}
                    >
                        <Select placeholder="Select a country" onChange={handleCountryChange}>
                            {
                                Object.keys(ISO3166_1Alpha2).map((key) => (
                                    <Option key={key} value={key}>{key}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    {addressDatas && renderSelect(addressDatas)}
                </Form>
                <Form>
                    {/* {finalPostNumber && <label>Post Number {finalPostNumber}</label>} */}
                    {finalPostNumber && <label>Post Number {finalPostNumber}</label>}
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[{ required: true, message: 'Please input your address!' }]}
                    >
                        <Input placeholder="Enter Address" value={address} onChange={e => setAddress(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddAddressOption;